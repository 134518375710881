<template>
  <div class="infodetails">
    <div class="common_page_title">
      <div class="guwidth">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/redianzixun' }"
            >热点资讯主页</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/infolist' }"
            >资讯列表</el-breadcrumb-item
          >
          <el-breadcrumb-item>{{infolist.title}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

   

    <div class="askdetail_main flex base_w">
      <div class="common_index_leftwidth">
        <div class="askarticbox">
          <p class="title">{{infolist.title}}</p>
          <div class="seebox">
            <span class="mr15">{{infolist.createTime}}</span>
            <span>浏览量：{{infolist.browse}}次</span>
          </div>
          <div class="line"></div>
          <div class="details" v-html="infolist.content"></div>
          <div class="line"></div>

          <div class="zanbox flexbox" >
            <p @click.stop="toggleStatus(1)">
              <img src="@/assets/img/zan2.png" alt=""  v-if="infolist.islike==0"/>
              <img src="@/assets/img/zan22.png" alt="" v-else/>
            </p>
            <p @click.stop="toggleStatus(2)">
              <img src="@/assets/img/xing2.png" alt=""  v-if="infolist.iscollect==0"/>
              <img src="@/assets/img/xing22.png" alt="" v-else/>
            </p>
          </div>
         
        </div>

        <div class="bg16" @click.stop="tourl('/toask')">
          <div class="btnbox">立即提问</div>
        </div>
      </div>
      <div class="common_index_rightwidth">
        <!-- 热门文章  -->
        <hotlist :list="inhighlist" @refresh="refresh"></hotlist>

        <!-- 优秀律师推荐  -->
        <div class="rmls">
          <div class="common_title_box">
            <span class="title">优秀律师推荐</span>
          </div>
          <hotls :list="Lawlist"></hotls>
        </div>
        <!-- 最新咨询 -->
        <div class="newzixun">
          <div class="common_title_box">
            <span class="title">最新咨询</span>
          </div>
          <div class="newziitem">
            <wenda :type="3" :wendalistArr="wentypedalist"></wenda>
            <div class="btnbox" @click.stop="tourl('/toask')">我要提问</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hotls from '@/components/hotls'
import hotlist from '@/components/hotlist'
import wenda from '@/components/wenda'

import apiUrl from '@/api/main'
export default {
  components: { hotls, hotlist, wenda },
  data() {
    return {
      infolist:{}, // 详情
      wentypedalist:[], // 问答列表
      Lawlist:[], // 律师列表
      inhighlist:[], // 浏览量高
    }
  },
  computed: {},
  watch: {},
  created() {
    
  },
  mounted() {
    this.getdetail();
    this.gettypewendalist(); // 问答
    this.lsLawyerlist(); // l律师
    this.getHighlist(); // 最新文章
    // 跳转回顶部
    document.documentElement.scrollTop = 0;
  },
  methods: {
   
    // 跳转
    tourl(url) {
      this.$router.push({
        path: url
      })
    },
    refresh(){
      this.getdetail();
      this.gettypewendalist(); // 问答
      this.lsLawyerlist(); // l律师
      this.getHighlist(); // 最新文章
      // 跳转回顶部
      document.documentElement.scrollTop = 0;
    },
    // 获取详情
    getdetail() {
      let data = {
        userId: localStorage.getItem('userId'),
        id: this.$route.query.id
      }
      apiUrl.lsInformationinfo(data).then((res) => {
          this.infolist = res.data;
      })
    },
    /**
     * 点赞收藏  type 1点赞 2 收藏
     * 
     * */ 
    toggleStatus(type){
      let url = '';
				if (!localStorage.getItem('userId')) return;
				if (type == 1) {
					//点赞
					url = apiUrl.likeinformation;
				} else {
					// 收藏
					url = apiUrl.collectinformation;
				}
        let data = {
          id: this.$route.query.id,
					userId: localStorage.getItem('userId')
        }
        url(data).then(res => {
          this.$message({
            message:res.msg,
            type:'success'
          })
					if (res.code == 0) {
						if (type == 1) {
							this.infolist.islike = this.infolist.islike == 0 ? 1 : 0;
						} else {
							this.infolist.iscollect = this.infolist.iscollect == 0 ?1 : 0;
						}
					}
				})
    },
      // 获取律师列表
      lsLawyerlist(){
      let data = { 
        pageNum: 1,
        pageSize: 3,
        orderByColumn: " ",
        isAsc: "desc",
      }
      apiUrl.lsLawyerindex(data).then((res) => {

          res.rows.forEach(element => {
            if(element.goodsIds){
              element.goodsIdsArr = element.goodsIds.split(',')
            }
          });
          this.Lawlist = res.rows;

      })
    },
      // 获取问答列表
      gettypewendalist() {
        let data = {
          pageSize: 2,
          pageNum: 1,
        }
        apiUrl.wendalist(data).then((res) => {

            this.wentypedalist = res.rows;

        })
      },
    // 浏览量高的文章
    getHighlist(){
      let data = {
        pageSize: 10,
        pageNum: 1,
        orderByColumn:'browseNum',
        isAsc:'desc'
      }
      apiUrl.lsInformationlist(data).then((res) => {
        this.inhighlist = res.rows
      })
    },
  },

}
</script>
<style lang='scss' scoped>
.infodetails {
  .askdetail_main {
    margin-top: 33px;
    padding-bottom: 67px;
    .common_index_leftwidth {
      .askarticbox {
        width: 830px;
        border-radius: 10px;
        border: 1px solid #e6e6e6;
        padding: 27px 20px;
        margin-bottom: 40px;
        .title {
          font-size: 28px;
          font-weight: 500;
          color: #333333;
          text-align: center;
        }
        .seebox {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 13px;
          margin-bottom: 24px;
          img {
            width: 17px;
            height: 17px;
            margin-right: 7px;
            margin-left: 20px;
          }
          span {
            font-size: 14px;
            color: #666666;
            line-height: 17px;
          }
        }
        .line {
          height: 1px;
          border: 1px dotted #dde2f8;
        }
        .details {
          font-size: 13px;
          color: #333333;
          line-height: 25px;
          margin: 20px 0;
        }
      }
    }
    // 律师推荐
    .rmls {
      margin-top: 33px;
      .common_title_box {
        margin-bottom: 13px;
      }
    }
    // 法律资讯
    .flzx {
      margin-top: 33px;
      .flzxbox {
        // width: 488px;
        width: 586px;
        min-height: 388px;
        background: #f8f8f8;
        border-radius: 10px;
        padding: 27px;
        margin-top: 13px;
      }
    }
    // 最新咨询
    .newzixun {
      margin-top: 33px;
      .common_title_box {
        margin-bottom: 13px;
      }
      .newziitem {
        // width: 488px;
        width: 586px;
        background: #f8f8f8;
        border-radius: 10px;
        padding: 0 27px 27px;
        .btnbox {
          width: 153px;
          height: 40px;
          line-height: 40px;
          border-radius: 30px;
          margin: 0 auto;
        }
      }
    }
    // 点赞收藏
    .zanbox {
      margin-top: 26px;
      text-align: center;
      cursor: pointer;
      justify-content: center;
      img {
        width: 48px;
        height: 48px;
        margin: 0 24px;
      }
    }
    .bg16 {
      background: url('../../assets/img/bg16.png') no-repeat;
      background-size: 100% 100%;
      height: 152px;
      margin-top: 67px;
      display: flex;
      .btnbox {
        width: 152px;
        height: 38px;
        line-height: 38px;
        border-radius: 19px;
        margin: 0px auto;
        margin-top: 94px;
        font-size: 17px;
      }
    }
  }
  .mr15{
    margin-right: 15px;
  }
}
</style>